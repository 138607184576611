<template>
  <div style="width: 100vw">
    <swiper :datalist="datalist"> </swiper>
    <div style="width: 1200px; margin: auto">
      <!-- 关于我们 -->
      <!-- <img style="margin: 105px 0" src="@/common/image/关于我们.png" alt="" />
        <img src="@/common/image/关于.png" alt="TITLE" /> -->
      <div class="flex listName flexsb">
        <div
          class="info"
          v-for="(i, index) in moshi"
          :key="index"
          v-show="index == 0"
          v-html="i.content"
        >
          <!-- {{}} -->
        </div>
      </div>
      <!-- 资讯动态 -->
      <zixun></zixun>
      <!-- 核心优势 -->
      <div>
        <img style="margin: 78px 0 50px" src="@/common/image/优势.png" alt="" />
        <div class="youshi flexsb" v-if="youshi && youshi.length > 0">
          <div
            class="liebiao wow lightSpeedIn"
            v-for="(i, k) in youshi"
            :key="k"
          >
            <div class="titleimg">
              <img :src="i.advantageIcon" alt="" />
              <div class="shenglue1">{{ i.advantageName }}</div>
            </div>
            <div class="listnamedata">
              {{ i.advantageInfo }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swiper from "@/components/swiper/swiper.vue";
import zixun from "@/components/zixun/zixun.vue";

export default {
  name: "index",
  components: { swiper, zixun },
  data() {
    return {
      datalist: [
        { url: require("@/common/image/banner1.png") },
        { url: require("@/common/image/banner2.png") },
        { url: require("@/common/image/banner3.png") },
      ], //轮播图
      moshi: [], //关于我们
      youshi: [], //核心优势
      screenWidth: document.body.clientWidth,     // 屏幕宽
      screeHeight: document.body.clientHeight,    // 屏幕高
    };
  },
  created() {
    // setTimeout(()=>{console.log(document.body.clientWidth,document.body.clientHeight)},0)//获取屏幕宽高
    // this.$cookies.set("user_session", "25j_7Sl6xDq2Kc3ym0fmrSSk2xV2XkUkX","1d")
    this.api.aboutUs().then((res) => {
      //关于我们
      this.moshi = res.data;
    });

    this.api.advantage().then((res) => {
      //核心优势
      this.youshi = res.data;
      false;
    });
  },
  mounted() {
  },
  wacth: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.listName {
  padding: 50px 0;
  box-sizing: border-box;
  .info {
    width: 1200px;
    margin: 0 20px;
    font-size: 14px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #666666;
  }
}
.youshi {
  height: 575px;
  width: 1200px;
  background-image: url(".././common/image/图层 774.png");
  background-size: cover;
  margin-bottom: 67px;
  padding-top: 82px;
  box-sizing: border-box;
  overflow-y: auto;

  .liebiao {
    width: 287px;
    min-height: 180px;
    height: fit-content;
    background: #ffffff;
    box-shadow: 0px 0px 23px 0px rgba(198, 198, 198, 0.22);
    .titleimg {
      text-align: center;
      padding: 37px 0 18px;
      img {
        max-width: 100%;
      }
      div {
        margin-top: 22px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
    }
    .listnamedata {
      padding: 0 24px 24px 24px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
  }
}
</style>
