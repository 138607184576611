<template>
  <div class="zixun">
    <div class="message">
      <img class="img" src="../../common/image/资讯动态.png" alt="" />
      <div class="bot">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <!-- 企业动态 -->
          <el-tab-pane label="企业动态" name="first">
            <div class="qiye flex" style="cursor: pointer">
              <div
                class="one"
                v-for="(i, k) in qiye"
                @click="getdetails(i.id)"
                :key="k"
              >
                <img class="dongtai" :src="i.img" alt="" />
                <h5 class="title shenglue1">{{ i.newsTitle }}</h5>
                <!-- <p class="time shenglue1">
                  {{ i.createTime }} | {{ i.newsContent }}
                </p> -->
              </div>
            </div>
          </el-tab-pane>
          <!-- 商家资讯 -->
          <el-tab-pane label="商家资讯" name="second">
            <div
              style="cursor: pointer"
              class="shangjia flexcont flexsb"
              @click="getdetails(i.id)"
              v-for="(i, k) in zixum"
              :key="k"
            >
              <div class="left">
                <h5 class="title">
                  {{ i.newsTitle }}
                </h5>
                <p class="content">
                  {{ i.newsContent }}
                </p>
              </div>
              <div class="right">{{ i.createTime }}</div>
            </div>
          </el-tab-pane>
          <!-- 会员资讯 -->
          <el-tab-pane label="会员资讯" name="third">
            <div
              style="cursor: pointer"
              class="shangjia flexcont flexsb"
              @click="getdetails(i.id)"
              v-for="(i, k) in huiyuan"
              :key="k"
            >
              <div class="left">
                <h5 class="title">
                  {{ i.newsTitle }}
                </h5>
                <p class="content">
                  {{ i.newsContent }}
                </p>
              </div>
              <div class="right">{{ i.createTime }}</div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="but">
          <button class="more" @click="$router.push('/consult')">
            查看更多
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "zixun",
  data() {
    return {
      activeName: "first",
      qiye: [], //企业动态
      zixum: [], //商家咨询
      huiyuan: [], //会员咨询
    };
  },
  created() {
    this.getzixun(1);
    this.getzixun(2);
    this.getzixun(3);
  },
  wacth: {},
  methods: {
    handleClick() {},
    // 资讯动态
    getzixun(e) {
      this.api.news({ pageNum: 1, pageSize: 3, type: e }).then((res) => {
        // console.log(res);
        switch (e) {
          case 1:
            this.qiye = res.data.rows.reverse();
            break;
          case 2:
            this.zixum = res.data.rows.reverse();
            break;
          case 3:
            this.huiyuan = res.data.rows.reverse();
            break;
          default:
            break;
        }
      });
    },
    // 详情跳转
    getdetails(e) {
      this.$router.push({ path: `/dynamicDetails/${e}` });
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.message {
  width: 1200px;
  margin: 0 auto;
  .img {
    width: 1200px;
    height: 75px;
    margin: 0px 0 73px;
  }
  .bot {
    background: #ffffff;
    border: 1px solid #fafafa;
    box-shadow: 1px 2px 9px 1px rgba(242, 239, 239, 0.73);
    margin-bottom: 10px;
    // min-height: 413px;
    // 企业
    .qiye {
      padding: 0 11px;
      .one {
        width: 380px;
        margin-right: 20px;
      }
      .dongtai {
        min-width: 380px;
        min-height: 185px;
        max-width: 380px;
        max-height: 185px;
        margin-bottom: 20px;
        // background-color: pink;
      }
      .title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-bottom: 15px;
        padding-left: 20px;
      }
      .time {
        font-size: 14px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #333333;
        padding-left: 20px;
      }
    }
    // 商家
    .shangjia {
      box-sizing: border-box;
      margin: 0 22px;
      padding: 22px 0;
      border-bottom: 1px solid #eeeeee;
      cursor: pointer;
      .title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-bottom: 13px;
      }
      .content {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .right {
        font-size: 14px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #666666;
      }
    }
    .but {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 24px;
      .more {
        width: 87px;
        height: 30px;
        border: 1px solid #981c1c;
        border-radius: 4px;
        background-color: #fff;
        color: #981c1c;
        cursor: pointer;
      }
    }
    .more {
      width: 87px;
      height: 30px;
      border: 1px solid #981c1c;
      border-radius: 4px;
      background-color: #fff;
      color: #981c1c;
    }
  }
}
/deep/.el-tabs__nav-wrap::after {
  width: 0;
}
/deep/.el-tabs__nav {
  width: 100%;
  background: #fafafa;
  display: flex;
  text-align: center;
}
/deep/.el-tabs__item {
  flex: 1;
}
/deep/.is-active {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #981c1c;
  background: #ffffff;
}
/deep/.el-tabs__active-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  background-color: #981c1c;
  z-index: 1;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  list-style: none;
}
/deep/.el-tabs__item:hover {
  color: #981c1c;
}
</style>
