<template>
  <div>
    <el-carousel height="520px" :interval="2500" >
      <el-carousel-item v-for="(i,k) in datalist" :key="k">
        <img style="width:100%;height:100%" :src="i.url" alt="banner">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  name: 'swiper',
  props:['datalist'],
  data() {
    return {

    }
  },
  create() { },
  wacth: {},
  methods: {},
  computed: {},
}
</script>
<style lang="scss" scoped>
</style>
